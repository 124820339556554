<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0" style="margin: 0 auto !important;">


            <div class="main_block">
                <div class="header_block_div">
                    <div class="reg_logo_section">
                        <a class="brand-logo" @click="toAequitex()">
                            <img src="../../assets/images/logo/logo-green.png"/>
                        </a>
                    </div>
                    <div class="reg-lang-section">
                        <div class="register-languages-mobile small_screen_lang" align="end">
                            <b-dropdown
                                text="Dark"
                                variant="outline-dark"
                                id="dropdown-grouped"
                                class="dropdown-language no-border-button"
                                right
                            >
                                <template #button-content>
                                    <b-img
                                        :src="currentLocale.img"
                                        height="14px"
                                        width="22px"
                                        :alt="currentLocale.locale"
                                    />
                                    <span class="ml-50 text-body">{{ $t(currentLocale.name) }}</span>
                                </template>
                                <b-dropdown-item
                                    v-for="localeObj in locales"
                                    :key="localeObj.locale"
                                    @click="$i18n.locale = localeObj.locale"
                                >
                                    <b-img
                                        :src="localeObj.img"
                                        height="14px"
                                        width="22px"
                                        :alt="localeObj.locale"
                                    />
                                    <span class="ml-50">{{ $t(localeObj.name) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>

                <div class="reg-content-section">

                    <b-col class="d-lg-flex align-items-center p-5 register_section_buttons" style="background-color:#F7F6F4; padding: 0 !important;">
                        <b-row class="col-md-12 flex-column align-items-start">
                            <template v-if="!member_company_id">
                                <h1 style="width: 100%; text-align: center">{{ $t('register_buttons_paragraph') }}</h1>
                                <ul>
                                    <template>
                                        <li>
                                            <p @click="showModal = true" style="cursor: pointer;">
                                                {{ $t('register_seller_button') }}
                                                <span>
                                                <feather-icon icon="InfoIcon"></feather-icon>
                                            </span>
                                            </p>
                                        </li>
                                    </template>

                                    <template>
                                        <li>
                                            <p @click="showModal2 = true" style="cursor: pointer;">
                                                {{ $t('register_buyer_button') }}
                                                <span>
                                                <feather-icon icon="InfoIcon"></feather-icon>
                                            </span>
                                            </p>
                                        </li>
                                    </template>
                                </ul>
                            </template>
                        </b-row>
                    </b-col>

                    <b-col class="row auth-bg px-1 p-2 pt-3" style="background-color:#F7F6F4; margin: 0 auto">
                        <div class="form_block">
                            <div class="form_block_header ">
                                <div class="col-md-12">
                                    <h4 class="row justify-content-between">
                                        <b-card-title
                                            title-tag="h2"
                                            class="font-weight-bold col-md-12 pl-0  pb-0 mb-0"
                                        >
                                            <span v-if="!member_company_id">{{ $t("register_text") }}</span>
                                            <span v-else>{{ $t("member_register_text") }}</span>
                                        </b-card-title>

                                    </h4>
                                </div>
                            </div>
                            <b-col sm="12" md="12" lg="12" class="px-xl-2 " style="padding: 20px;">
                                <validation-observer ref="registerValidation">
                                    <b-form class="auth-login-form mt-2" @submit.prevent>
                                        <b-row class="m-0">
                                            <!-- first name -->
                                            <b-form-group
                                                class="col-12 col-sm-6 p-0"
                                                :label="$t('first_name')"
                                                label-for="login-first-name"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="First Name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="login-first-name"
                                                        v-model.trim="userFirstName"
                                                        tabindex="1"
                                                        :state="errors.length > 0 ? false : null"
                                                        name="login-first-name"
                                                        placeholder="John"
                                                        :disabled="disableInputs"
                                                    />
                                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                            <!-- last name -->
                                            <b-form-group
                                                class="col-12 col-sm-6 p-0 pl-md-1"
                                                :label="$t('last_name')"
                                                label-for="login-last-name"
                                            >
                                                <validation-provider
                                                    #default="{ errors }"
                                                    name="Last Name"
                                                    rules="required"
                                                >
                                                    <b-form-input
                                                        id="login-last-name"
                                                        v-model.trim="userLastName"
                                                        :state="errors.length > 0 ? false : null"
                                                        name="login-last-name"
                                                        tabindex="2"
                                                        placeholder="Doe"
                                                        :disabled="disableInputs"
                                                    />
                                                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-row>
                                        <!-- email -->

                                        <b-form-group :label="$t('email')" label-for="login-email">
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Email"
                                                rules="required|email"
                                            >
                                                <b-form-input
                                                    id="login-email"
                                                    v-model.trim="userEmail"
                                                    :state="errors.length > 0 ? false : null"
                                                    name="login-email"
                                                    tabindex="3"
                                                    placeholder="john@example.com"
                                                    :disabled="disableInputs"
                                                />
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                        <!-- forgot password -->
                                        <b-form-group>
                                            <div class="d-flex justify-content-between">
                                                <label for="login-password">{{ $t("password") }}</label>
                                            </div>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="Password"
                                                rules="required"
                                            >
                                                <b-input-group
                                                    class="input-group-merge"
                                                    :class="errors.length > 0 ? 'is-invalid' : null"
                                                >
                                                    <b-form-input
                                                        id="login-password"
                                                        v-model.trim="password"
                                                        tabindex="4"
                                                        :state="errors.length > 0 ? false : null"
                                                        class="form-control-merge"
                                                        :type="passwordFieldType"
                                                        name="login-password"
                                                        placeholder="············"
                                                        :disabled="disableInputs"
                                                    />
                                                    <b-input-group-append is-text>
                                                        <feather-icon
                                                            class="cursor-pointer"
                                                            :icon="passwordToggleIcon"
                                                            @click="togglePasswordVisibility"
                                                        />
                                                    </b-input-group-append>
                                                </b-input-group>
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                            <div class="" align="end">
                                                <b-link
                                                    tabindex="5"
                                                    :to="{ name: 'auth-forgot-password-v2' }"
                                                >
                                                    <small> {{ $t("forgot_password") }}</small>
                                                </b-link>
                                            </div>
                                        </b-form-group>

                                        <b-form-group v-if="!member_company_id">
                                            <div class="d-flex justify-content-between">
                                                <label for="question">{{ $t("how_did_you_become_aware_of_us") }}</label>
                                            </div>

                                            <b-form-select
                                                :options="questionOptions"
                                                id="question"
                                                name="question"
                                                :disabled="disableInputs"
                                                v-model="question"
                                            ></b-form-select>
                                        </b-form-group>

                                        <b-form-group>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="terms-and-conditions"
                                                rules="required"
                                            >
                                                <b-form-checkbox
                                                    id="terms-and-conditions"
                                                    v-model.trim="terms_and_conditions"
                                                    name="terms-and-conditions"
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                    tabindex="6"
                                                >
                                                    {{ $t("terms_i_accept_the") }}
                                                    <a @click="toTerms()" target="_blank">
                                                        {{ $t("terms_link") }}
                                                    </a>

                                                    <!-- <a
                                                    href="/files/light_de.pdf"
                                                    target="_blank"
                                                    v-if="language == 'de'"
                                                    >{{ $t("terms_link") }}</a
                                                    >

                                                    <a href="/files/light_eng.pdf" target="_blank" v-else></a> -->
                                                </b-form-checkbox>
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                        <!-- remember-me -->
                                        <b-form-group>
                                            <validation-provider
                                                #default="{ errors }"
                                                name="data-privacy"
                                            >
                                                <b-form-checkbox
                                                    id="data-privacy"
                                                    v-model.trim="data_privacy"
                                                    name="data-privacy"
                                                    :state="errors.length > 0 ? false : null"
                                                    :disabled="disableInputs"
                                                    tabindex="7"
                                                >
                                                    {{ $t("newsletter_text") }}
                                                </b-form-checkbox>
                                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                        <!-- submit buttons -->
                                        <b-button
                                            type="submit"
                                            variant="primary"
                                            block
                                            @click="validationForm"
                                            :disabled="resLoader"
                                            tabindex="8"
                                        >
                                            <img height="20" v-if="resLoader" src="/new-loader.svg"/> {{ $t("register")
                                            }}
                                        </b-button>
                                    </b-form>
                                </validation-observer>

                                <b-card-text class="text-center mt-2">
                                    <span>{{ $t("already_registered") }} </span>
                                    <b-link tabindex="9" :to="{ name: 'login' }">
                                        <span>&nbsp; {{ $t("login_here") }}</span>
                                    </b-link>
                                </b-card-text>
                            </b-col>
                        </div>
                    </b-col>

                    <b-col class="pb-5" style="background: rgb(247, 246, 244);">
                        <iframe width='100%' height='730px' src='https://support-aequitex.zohobookings.eu/portal-embed#/customer/167264000000318034' frameborder='0' allowfullscreen=''></iframe>
                    </b-col>

                </div>

            </div>


            <!-- /Login-->
        </b-row>


        <!-- Buyer modal -->
        <b-modal v-model="showModal" :title="$t('register_seller_button')" hide-footer size="lg" class="custom_modal">
            <div class="modal_section">
                <ul type="circle">
                    <li>
                        {{ $t('seller_section1_paragraph') }}
                    </li>
                    <ul type="square">
                        <li>{{ $t('seller_section1_answer1') }}</li>
                        <li>{{ $t('seller_section1_answer2') }}</li>
                        <li>{{ $t('seller_section1_answer3') }}</li>
                    </ul>
                </ul>

            </div>

            <div class="modal_section">
                <ul type="circle">
                    <li>
                        {{ $t('seller_section2_paragraph') }}
                    </li>
                    <ul type="square">
                        <li>{{ $t('seller_section2_answer1') }}</li>
                        <li>{{ $t('seller_section2_answer2') }}</li>
                        <li>{{ $t('seller_section2_answer3') }}</li>

                        <ul type="square">
                            <li>{{ $t('seller_section2_answer4') }}</li>
                            <li>{{ $t('seller_section2_answer5') }}</li>
                        </ul>
                    </ul>
                </ul>
            </div>
        </b-modal>

        <!-- Seller modal -->
        <b-modal v-model="showModal2" :title="$t('register_buyer_button')" hide-footer size="lg" class="custom_modal">
            <div class="modal_section">
                <ul type="circle">
                    <li>
                        {{ $t('buyer_section1_paragraph') }}
                    </li>
                    <ul type="square">
                        <li>{{ $t('buyer_section1_answer1') }}</li>
                        <li>{{ $t('buyer_section1_answer2') }}</li>
                    </ul>
                </ul>

            </div>

            <div class="modal_section">
                <ul type="circle">
                    <li>
                        {{ $t('seller_section2_paragraph') }}
                    </li>
                    <ul type="square">
                        <li>{{ $t('buyer_section2_answer1') }}</li>
                        <li>{{ $t('buyer_section2_answer2') }}</li>
                        <li>{{ $t('buyer_section2_answer3') }}</li>
                        <li>{{ $t('buyer_section2_answer4') }}</li>
                        <li>{{ $t('buyer_section2_answer5') }}</li>
                    </ul>
                </ul>
            </div>
        </b-modal>

    </div>
</template>

<script>
  /* eslint-disable global-require */
  import {ValidationProvider, ValidationObserver} from "vee-validate";
  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
  } from "bootstrap-vue";
  import {required, email} from "@validations";
  import {togglePasswordVisibility} from "@core/mixins/ui/forms";
  import store from "@/store/index";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import i18n from "@/libs/i18n";

  export default {
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        showModal: false,
        showModal2: false,
        question: '',
        questionOptions: [
          {value: '', text: this.$t('select_one'), disabled: true},
          {value: 'google_search', text: this.$t('google_search')},
          {value: 'bing_search', text: this.$t('bing_search')},
          {value: 'linkedin', text: this.$t('linkedin')},
          {value: 'facebook', text: this.$t('facebook')},
          {value: 'instagram', text: this.$t('instagram')},
          {value: 'twitter', text: this.$t('twitter')},
          {value: 'media', text: this.$t('media')},
          {value: 'recommendation', text: this.$t('recommendation')},
          {value: 'direct_contact_e_mail_or_network_event', text: this.$t('direct_contact_e_mail_or_network_event')},
          {value: 'other', text: this.$t('other')},
        ],
        member_company_id: this.$route.query.company_id ?? null,
        member_role: this.$route.query.role ?? null,
        resLoader: false,
        terms_and_conditions: "",
        data_privacy: "",
        language: "en",
        password: "",
        userEmail: "",
        userFirstName: "",
        userLastName: "",
        disableInputs: false,
        buyer: 0,
        role: null,
        sideImg: "",
        // validation rules import store from '@/store/index'
        required,
        email,
        locales: [
          {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          },

          {
            locale: "de",
            img: "/images/flags/german.png",
            name: "german",
          },
        ],
        roleBuyer: false,
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
      },
      imgUrl() {
        if (this.$route.path.includes("buyer")) {
          this.sideImg = require("@/assets/images/pages/login-v5.png");
        } else {
          this.sideImg = require("@/assets/images/pages/login-v5.png");
        }
        return this.sideImg;
      },
      currentLocale() {
        let obj = null;
        if (this.$i18n.locale) {
          if (this.$i18n.locale == "en") {
            obj = {
              locale: "en",
              img: "/images/flags/en.png",
              name: "english",
            };
          } else {
            obj = {
              locale: "de",
              img: "/images/flags/german.png",
              name: "german",
            };
          }
        } else {
          obj = {
            locale: "en",
            img: "/images/flags/en.png",
            name: "english",
          };
        }
        return obj;
      },
    },
    created() {
      this.role = this.$route.meta.role;
      if (
          this.$route.params &&
          this.$route.params.lang &&
          (this.$route.path.includes("en") || this.$route.path.includes("de"))
      ) {
        this.$i18n.locale = this.$route.params.lang;
      }
      if (this.$route.path.includes('buyer')) {
        this.roleBuyer = true;
      }
    },
    methods: {
      // OpenModalChangeButton(status, modal) {
      //   this[modal] = true
      //   this.buyer = status
      // },
      toAequitex() {
        window.location = 'https://www.aequitex.com/'
      },
      registerAs(type) {
        if (type == 'seller') {
          this.$router.push('/register');
          this.roleBuyer = false;
        } else if (type == 'buyer') {
          this.$router.push('/register/buyer');
          this.roleBuyer = true;
        }
      },
      toTerms() {
        if (this.$i18n.locale == "en") {
          window.open("https://www.aequitex.com/terms-condition", "_blank");
        } else {
          window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
        }
      },
      downloadDataProtection() {
      },
      validationForm() {
        this.resLoader = true;

        const member_company_id = this.$route.query.company_id ?? null;
        const member_role = this.$route.query.role ?? null;

        this.$refs.registerValidation.validate().then((success) => {
          if (this.terms_and_conditions != true) {
            this.terms_and_conditions = "";
            this.$refs.registerValidation.validate();
            this.resLoader = false;
            return "";
          }
          if (success) {
            this.disableInputs = true;
            if (this.$route.path.includes("buyer")) {
              this.role = "buyer";
            } else {
              this.role = "seller";
            }
            try {
              this.$http
                  .post("/register", {
                    first_name: this.userFirstName,
                    last_name: this.userLastName,
                    email: this.userEmail,
                    password: this.password,
                    password_confirmation: this.password,
                    language: this.currentLocale.locale,
                    affiliate_code: this.role == "seller" ? (this.$route.query.affiliate_code ? this.$route.query.affiliate_code : null) : null,
                    partner_code: this.$route.query.partner_code ? this.$route.query.partner_code : null,
                    question: this.question,
                    company_id: member_company_id,
                    member_role: member_role,
                  })
                  .then((res) => {
                    if (res.data.status === 200) {
                      const user = res.data.value;

                      if (member_company_id || user.status === 1) {
                        this.$store.commit("auth/SET_AUTHENTICATED", true);
                        this.$store.commit("auth/SET_USER", user);
                        this.$store.commit("auth/UPDATE_TOKEN", user.token);
                        window.location.href = "/";
                      } else {
                        this.$store.commit("auth/SET_AUTHENTICATED", true);
                        this.$store.commit("auth/SET_USER", user);

                        this.$router.push("/verify");
                      }
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.$t("successfully_registered"),
                          icon: "SuccessIcon",
                          variant: "success",
                        },
                      });
                    } else {
                      this.disableInputs = false;
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.$t("failed_to_register_with_provided_credentials"),
                          icon: "ErrorIcon",
                          variant: "error",
                        },
                      });
                    }
                  });
            } catch (error) {
              console.error("Error:", error);
              // Handle error
              this.resLoader = false;
            }
          }
        });
      },
    },
  };
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";

    .header_block_div > a, .header_block_div > div {
        width: 10%;
    }

    .form_block {
        background-color: white;
        width: 100%;
        border-radius: 25px;
        overflow: hidden;
        height: fit-content;
        /*margin-top: 160px*/
    }

    .form_block .form_block_header {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #555;
        padding: 20px;
    }

    .form_block .form_block_header h2, .form_block .form_block_header span {
        color: #222f28;
    }

    .form_block input, .form_block select {
        background-color: #F7F6F4;
    }

    .form_block button {
        background-color: #577264 !important;
    }

    .modal_section {
        width: 98%;
        min-height: 150px;
        height: auto;
        padding: 20px 10px 20px 20px;
        font-weight: 500;
    }

    .modal_section:first-of-type {
        border-bottom: 1px solid #ccc;
    }

    .custom_modal .modal-dialog {
        max-width: 1200px !important;
    }

    .modal_section li {
        margin: 15px 0;
    }

    .register_section_buttons p {
        margin: 15px 0;
        font-size: 16px;
    }

    /*.small_screen_lang {*/
    /*display: none;*/
    /*}*/

    .large_screen_lang {
        display: block;
    }

    /*@media screen and (max-width: 700px) {*/
    /*.form_block {*/
    /*margin-bottom: 70px !important;*/
    /*}*/
    /*}*/

    @media screen and (max-width: 992px) {
        /*.form_block {*/
        /*margin: 0 20px;*/
        /*}*/
        .register_section_buttons {
            margin-top: 25px;
            padding: 0 40px !important;
        }

        .small_screen_lang {
            display: block;
            margin: 25px 0;
            text-align: right !important;
        }

        .large_screen_lang {
            display: none;
        }
    }
</style>
<style scoped>

    .main_block {
        width: 2100px;
        max-width: 2100px;
        margin: 0 auto;
    }

    .reg-content-section {
        width: 100%;
        height: 100%;
        display: flex;
        /*flex-direction: column;*/
    }

    .reg-content-section > div {
        width: 33%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .header_block_div {
        width: 100%;
        height: 70px;
        display: flex;
        align-items: center;
        background-color: #F7F6F4
    }

    .brand-logo {
        position: unset !important;
    }

    .reg_logo_section {
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .reg-lang-section {
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    iframe {
        margin-top: 150px;
    }

    @media screen and (max-width: 1250px) {

        .header_block_div > div {
            width: 50%;
        }

        .reg-content-section {
            flex-direction: column;
        }

        .reg-content-section > div {
            width: 100%;
            justify-content: center;
        }

        .register_section_buttons {
            margin: 0 !important;
        }

        .register_section_buttons ul {
            margin: 0 auto;
        }

        .form_block {
            width: 50%;
        }

        iframe {
            width: 50%;
        }
    }

    @media screen and (max-width: 800px) {
        .form_block {
            width: 100%;
            margin: 0 !important;
            padding: 0 !important;
        }

        iframe {
            margin-top: 40px;
            width: 100%;
        }

        .register_section_buttons h1 {
            font-size: 22px;
        }

        .register_section_buttons p {
            font-size: 14px;
        }
    }

</style>
